import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"
const UnderConstruction = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Under Construction" />
      <h1>Get ready for something awesome</h1>
      <Img
        fluid={data.avatar.childImageSharp.fluid}
        alt="installing a bunch of software"
      ></Img>
      {/* <p>We're building something great.</p> */}
    </Layout>
  )
}

export default UnderConstruction

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/tool.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
